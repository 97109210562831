<template lang="pug">
  .matchings-table
    .matchings-table-content
      table.container-fluid
        thead
          tr
            th.sticky-col.sync.sortable(@click="handleSorting('sync')")
              span {{ $t("shop_matching.sync") }}
              FaIcon.icon(:icon="sortingIcon('sync')")
            th.sticky-col.item-name.sortable(@click="handleSorting('name')")
              span {{ $t("shop_matching.shop_name") }}
              FaIcon.icon(:icon="sortingIcon('name')")
            th.dp.sortable(v-if="currentOrganization.dp" @click="handleSorting('dp')")
              span {{ $t("shop_matching.dp") }}
              FaIcon.icon(:icon="sortingIcon('dp')")
            th.item-name-dropdown.sortable(
              @click="handleSorting('inventory_groups.name')"
              v-if="isOrganizationWithSharedInventory"
            )
              span {{ $t("shop_matching.inventory_group_name") }}
              FaIcon.icon(:icon="sortingIcon('inventory_groups.name')")
            th.sources-item(
              v-for="{ id, name, rawName } in translatedOtaList"
              :class="rawName"
              :key="id"
            )
              | {{ name }}
            th.actions
        tbody
          template(v-for="shopsStore in [shopsAdded, shops]")
            ShopItem(
              v-for="(shop, index) in shopsStore"
              :key="shop.id || shop._addedAt"
              :item="shop"
              :index="index"
              :ota-list="translatedOtaList"
              :sources-items-loading="sourcesShopsLoading"
              :sources-items-by-ota-id="sourcesShopsByOtaId"
              :selected-sources-items-by-ota-id="selectedSourcesShops"
              :inventory-groups-list="mapInventoryGroupWithDeletable(inventoryGroupsList, shops)"
              :inventory-groups-loading="inventoryGroupsLoading"
              :create-inventory-group="createInventoryGroup"
              :update-inventory-group="updateInventoryGroup"
              :delete-inventory-group="handleDeleteInventoryGroup"
              :update-item="updateItem(shop)"
              :delete-item="() => deleteItem(shop)"
              :add-selected-sources-item="addSelectedSourcesShop"
              :remove-selected-sources-item="removeSelectedSourcesShop"
              :immutable-ota-ids="shop.immutable_ota_ids"
              :invalid-item-ids="invalidItemIds"
              :is-active-shops-count-limit-exceed="isActiveShopsCountLimitExceed"
            )
</template>

<script>
  // mixins
  import withSorting from "@/mixins/withSorting"
  import withBrowserDetect from "@/mixins/withBrowserDetect"
  import withConfirmation from "@/mixins/withConfirmation"

  // misc
  import { mapGetters } from "vuex"
  import { uniq } from "lodash"
  import { isSharedInventoryEnabled as isOrganizationWithSharedInventory } from "@/helpers/organization"

  export default {
    mixins: [withSorting, withBrowserDetect, withConfirmation],

    props: {
      shops: Array,
      shopsAdded: Array,
      sourcesShopsByOtaId: Object,
      inventoryGroupsList: Array,
      inventoryGroupsLoading: Boolean,
      createInventoryGroup: Function,
      updateInventoryGroup: Function,
      deleteInventoryGroup: Function,
      updateShop: Function,
      updateAddedShop: Function,
      deleteShop: Function,
      deleteAddedShop: Function,
      selectedSourcesShops: Object,
      addSelectedSourcesShop: Function,
      removeSelectedSourcesShop: Function,
      sourcesShopsLoading: {
        type: Boolean,
        default: true
      },
      invalidItemIds: {
        type: Array,
        default: () => new Array()
      },
      isActiveShopsCountLimitExceed: {
        type: Boolean,
        default: false
      },
      sortingData: Object
    },

    components: {
      ShopItem: () => import("./ShopItem")
    },

    computed: {
      ...mapGetters(["translatedOtaList", "currentOrganization"]),

      isOrganizationWithSharedInventory
    },

    methods: {
      handleDeleteInventoryGroup(inventoryGroupId) {
        this.deleteInventoryGroup(inventoryGroupId).then(() => {
          this.shops.forEach((shop, index) => {
            if (shop.inventory_group.id === inventoryGroupId) {
              const item = { ...shop, inventory_group: {} }
              this.updateShop({ item, index })
            }
          })

          this.shopsAdded.forEach((shop, index) => {
            if (shop.inventory_group.id === inventoryGroupId) {
              const item = { ...shop, inventory_group: {} }
              this.updateAddedShop({ item, index })
            }
          })
        })
      },

      updateItem(item) {
        return item._addedAt ? this.updateAddedShop : this.updateShop
      },

      deleteItem(shop) {
        this.$confirm({
          title: this.$t("shop_matching.delete_confirmation", { name: shop.name }),
          resolve: {
            handler: () => {
              const deleteMethod = shop._addedAt ? this.deleteAddedShop : this.deleteShop
              const index = shop._addedAt
                ? this.shopsAdded.findIndex(shopItem => shopItem.id === shop.id)
                : this.shops.findIndex(shopItem => shopItem.id === shop.id)
              deleteMethod(index)
            }
          }
        })
      },

      mapInventoryGroupWithDeletable(inventoryGroupsList, shops) {
        const groupIdsUsedByShops = uniq(shops.map(shop => shop.inventory_group.id))

        return inventoryGroupsList.map(group => ({
          ...group,
          deletable: !groupIdsUsedByShops.includes(group.id)
        }))
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/matchings.sass"

  .matchings-table
    +matchings-table-header-sticky
</style>
